.display-common-utilities() {
  .d-block { display: block !important; }

  .d-flex { display: flex !important; }
  .flex-row { display: flex !important; flex-direction: row !important; }
  .flex-column { display: flex !important; flex-direction: row !important; }
  .align-items-center { align-items: center !important; }
  .align-items-start { align-items: flex-start !important; }
  .align-items-end { align-items: flex-end !important; }

  .justify-content-center { justify-content: center !important; }
  .justify-content-start { justify-content: flex-start !important; }
  .justify-content-end { justify-content: flex-end !important; }

  .justify-content-between { justify-content: space-between !important; }
  .justify-content-around { justify-content: space-around !important; }
}
