.select-image {
  background-color: #cc0000;
  cursor: pointer;
  border: none;
  color: #fff;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 5.4px 18px;
  align-self: center;
  margin-bottom: 1px;
}

.select-image:hover {
  background: #d92923;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

