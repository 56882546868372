@import '../../../Assets/less/variables';

.logged-out-page {
  .inner-content {
    width: 100%;
    max-width: 350px;

    .default-alert {
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translate(-50%);
      max-width: 350px;
    }
  }
}

