.background-common-utilities() {
  .bg-primary { background-color: @primary-color !important; }
  .bg-link    { background-color: @link-color    !important; }
  .bg-success { background-color: @success-color !important; }
  .bg-warning { background-color: @warning-color !important; }
  .bg-error   { background-color: @error-color   !important; }
  .bg-dark    { background-color: @dark-color    !important; }
  .bg-light   { background-color: @light-color   !important; }
  .bg-white   { background-color: @white         !important; }
}
