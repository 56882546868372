@import '~antd/dist/antd.less';
@import 'variables';

@import 'utility/backgound';
@import 'utility/spacing';
@import 'utility/display';
@import 'utility/border';
@import 'utility/text';

.text-common-utilities;
.text-responsive-utilities;
.text-color-utilities;

.spacing-common-utilities;
.spacing-responsive-utilities;

.background-common-utilities;

.display-common-utilities;

.border-radius-loop(5);

// Global definitions
.ant-message-custom-content.ant-message-error {
  white-space: pre-wrap;
}

.grecaptcha-badge {
  visibility: hidden;
}
