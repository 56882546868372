.ant-row.ant-row-center.ant-row-middle.px-1 {
    
}

.fogot-password {
  position: absolute;
  left: 160px;
}

.fogot-password:hover {
  cursor: pointer;
  text-decoration: underline;
}

.box-help {
  display: flex;
  flex-direction: column;
  max-width: 550px !important;
  align-items: center;
  text-align: center;
  margin: auto;
  min-height: 100vh !important;
  justify-content: center;
}

.box-help h1 {
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0px !important;
  line-height: 22px;
  color: #CC0000;
}

.box-help h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  color: #CC0000;
}

.box-help .content {
  background: white;
  padding: 20px 100px;
  border-radius: 1.5em;
  border: 1px solid rgb(204, 204, 204);
}

.btn-help {
  color: #fff !important;
  background: #CC0000 !important;
  border-color: #CC0000 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
  padding: 0px 50px !important;
  margin-top: 20px;
}

.btn-help:hover {
  background: #d92923 !important;
  border-color: #d92923 !important;
}

.second-btn {
  color:#CC0000 !important;
  border-color: #CC0000 !important;
}

.second-btn:hover {
  color:#fff !important;
  border-color: #d92923 !important;
  background: #d92923 !important;
}

.align-center {
  max-width: 170px;
  margin: auto;
}

@media (max-width: 600px)
{
  .fogot-password{
    position: absolute;
    left: 0px;
  }

  .box-help .content {
    padding: 20px 70px;
    margin: 0 10px
  }

  .align-center {
    max-width: 100% !important;
    margin: auto;
  }
}

@media (max-width: 426px)
{
  .box-help .content {
    padding: 20px 50px;
  }
}

@media (max-width: 321px)
{
  .box-help .content {
    padding: 20px 35px;
  }
}