p {
  font-size: 16px;
  margin-bottom: 3px !important;
}

.alignP {
  min-width: 290px;
  margin-right: 50px;
}

/* #root > div > section > section > main > div > div > main > div.ant-row > div.ant-row.ant-row-space-between.ant-row-middle > div {
  max-width: none;
} */