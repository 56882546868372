.ant-menu-dark .ant-menu-inline.ant-menu-sub {
}

#root > div > section > aside > span {
  color: #cc0000 !important;
  top: 10px !important;
}

.itemMenu {
  margin-left: 48px;
  display: flex;
  flex-direction: row;
  line-height: 40px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
}

.itemMenuInfo {
  margin-right: 14px;
  font-size: 18px;
  color: white;
}

.itemMenuInfo:hover {
  cursor: help
}
